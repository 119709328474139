import React from 'react';

import { Link } from 'gatsby';

import imgback from '../../public/images/hero/hero-three-bg.png';

const Hero = ({ setting }) => {
  const redirect = process.env.GATSBY_REDIRECT_BASE_URL;

  return (
    <section
      className=' hero--three  clear__top pos__rel over__hi bg__img'
      style={{
        backgroundImage: `url(${setting.cover_image || imgback})`,
      }}
    >
      {/* <section
      className='hero hero--two hero--three  '
      style={{ backgroundImage: `url(${imgback})`, height: '100vh' }}
    > */}
      {/* <Box sx={{backgroundImage:imgback}}> */}
      <div className='container' style={{ backgroundImage: '#acaa11' }}>
        <div className=''>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='hero__content'>
                <h5 className='neutral-top'>
                  Smart & Secure Real Estate Investment Platform
                </h5>
                {/* <h1>Invest in the future of real estate</h1> */}
                <h1>{setting.description}</h1>
                {/* <h1>{'Coming soon...'}</h1> */}
                {/* <p className='primary neutral-bottom'>
                  {setting.og_description}
                </p> */}
                <p className='primary neutral-bottom'>
                  Start earning yearly income and capital growth with our
                  property-backed Investment
                </p>
                <div className='hero__cta__group'>
                  {/* <Link
                    to='/business-loan'
                    className='button button--secondary button--effect'
                  >
                    Get Started
                  </Link> */}
                  <a
                    href={`${redirect}/sign-up`}
                    className='button button--secondary button--effect'
                  >
                    Join Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
