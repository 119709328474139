import cashout from '/public/images/icons/cashout.png';
import reinvest from '/public/images/icons/reinvest.png';
import secure from '/public/images/icons/investors.png';
import transparent from '/public/images/icons/annual-return.png';
import * as React from 'react';

const Invest = () => {
  return (
    <section className='why__invest__two section__space'>
      <div className='container'>
        <div className='why__invest__two__area wow fadeInUp'>
          <div className='section__header'>
            <h5 className='neutral-top'>Smarter Property Investing</h5>
            <h2>Why Invest in Real Estate?</h2>
            <p className='neutral-bottom'>
              Start building your real estate investment portfolio today.
            </p>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-xxl-3'>
              <div className='why__invest__two__single__item'>
                <img width={'80rem'} src={secure} alt='Secure Investment' />
                <h5>Buy at low price</h5>
                <p className='neutral-bottom'>
                  Buying undeveloped land at low prices.
                </p>
              </div>
            </div>
            <div className='col-sm-6 col-xxl-3'>
              <div className='why__invest__two__single__item'>
                <img
                  width={'80rem'}
                  src={transparent}
                  alt='Transparent Platform'
                />
                <h5>Assured yield</h5>
                <p className='neutral-bottom'>
                  The land price will never depreciate.
                </p>
              </div>
            </div>
            <div className='col-sm-6 col-xxl-3'>
              <div className='why__invest__two__single__item'>
                <img src={cashout} alt='Cash out any time' />
                <h5>Land appreciation</h5>
                <p className='neutral-bottom'>
                  The price increases once the land is developed.
                </p>
              </div>
            </div>
            <div className='col-sm-6 col-xxl-3'>
              <div className='why__invest__two__single__item'>
                <img src={reinvest} alt='Reinvest' />
                <h5>High value plantation</h5>
                <p className='neutral-bottom'>
                  Income from the high value plantation products like dates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invest;
