import { Typography, Box, Modal } from '@mui/material';
import { flexbox } from '@mui/system';
import { Link } from 'gatsby';
import * as React from 'react';
import { useRef } from 'react';
import Countdown from 'react-countdown';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaClock, FaMapMarkerAlt, FaWindowClose } from 'react-icons/fa';
import CountdownDisplay from '../common/CountdownDisplay';
import placeholderProperty from '/public/images/property/property-placeholder.png';
import Slider from 'react-slick';
import '../../styles/scss/slider/slick-slider.scss';

const ProductCard = ({ singleItem }) => {
  const redirect = process.env.GATSBY_REDIRECT_BASE_URL;

  const [opendetails, setOpenDetails] = React.useState(false);
  const handleOpenDetails = () => setOpenDetails(true);
  const handleCloseDetails = () => setOpenDetails(false);
  const [openImages, setOpenImages] = React.useState(false);
  const handleImagesOpenDetails = () => setOpenImages(true);
  const handleImagesCloseDetails = () => setOpenImages(false);

  const {
    title = singleItem.title || singleItem.name,
    location = singleItem.nearest_town +
      ', ' +
      singleItem.state +
      ', ' +
      singleItem.country,
    price,
    rate_of_dividend,
    area_size,
    share_price,
    img = Array.isArray(singleItem?.image_urls) &&
    singleItem?.image_urls.length > 0
      ? singleItem?.image_urls[0].url
      : placeholderProperty,
    investLink = 'abcd',
  } = singleItem;
  const settings = {
    dots: true, // Show dots navigation
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed in milliseconds
    arrows: true, // Show previous and next arrows
    prevArrow: <div className='slick-prev' />,
    nextArrow: <div className='slick-next' />,
  };
  return (
    <div className='property__grid__single'>
      <div>
        <Modal
          open={opendetails}
          onClose={handleCloseDetails}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='position-relative d-flex flex-column justify-content-center min-vh-100 overflow-hidden'>
            <div className='container p-6 mx-auto bg-white rounded-md shadow-lg'>
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: '15px',
                }}
                onClick={() => handleCloseDetails()}
              >
                <FaWindowClose />
              </Box>
              <h3
                className='text-3xl font-bold text-center text-stone-900 capitalize'
                onClick={handleOpenDetails}
              >
                {singleItem.title}
              </h3>
              <h5 className='text-l text-center text-stone-800 capitalize'>
                {singleItem.subtitle}
              </h5>

              <div className='property__grid__single m-2 p-6 text-center'>
                <Box
                  sx={{
                    display: 'flex',
                    fontWeight: '500',
                    fontSize: '1rem',
                    pt: 1,
                  }}
                  className=' d-flex align-items-center gap-1'
                >
                  <FaMapMarkerAlt />{' '}
                  {`${singleItem.state}, ${singleItem.country}`}
                </Box>
                <div className='property__grid__single__inner'>
                  <Box sx={{ display: 'flex' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: singleItem.description,
                      }}
                      className={'itemStyles p-1'}
                    />
                  </Box>

                  <div className='item__info'>
                    <div className='item__info__single'>
                      <>
                        <p>Price</p>
                        <h6>{`${singleItem.price} ₹`}</h6>
                      </>
                    </div>

                    <div className='item__info__single'>
                      <>
                        <p>Area Size</p>
                        <h6>{`${singleItem.area_size}` || '-'}</h6>
                      </>
                    </div>
                  </div>
                </div>
                <div className='item__cta__group'>
                  <Link
                    to={redirect}
                    className='button button--secondary button--effect'
                  >
                    Invest Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          open={openImages}
          onClose={handleImagesCloseDetails}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='position-relative d-flex flex-column justify-content-center min-vh-100 overflow-hidden'>
            <div className='container p-6 mx-auto bg-white rounded-md shadow-lg'>
              <Box
                sx={{
                  cursor: 'pointer',
                  marginTop: '15px',
                }}
                onClick={() => handleImagesCloseDetails()}
              >
                <FaWindowClose />
              </Box>
              <h3
                className='text-3xl font-bold text-center text-stone-900 capitalize'
                onClick={handleImagesOpenDetails}
              >
                {singleItem.title}
              </h3>
              <h5 className='text-l text-center text-stone-800 capitalize'>
                {singleItem.subtitle}
              </h5>

              <div className='property__grid__single m-2 p-6 text-center'>
                <Slider {...settings}>
                  {singleItem?.image_urls !== null ? (
                    singleItem?.image_urls.map((image, index) => (
                      <div
                        key={index}
                        className='img__effect row d-flex align-items-center'
                      >
                        <img
                          src={image.url}
                          alt={`Image ${index + 1}`}
                          width={'500px'}
                          height={'500px'}
                        />
                      </div>
                    ))
                  ) : (
                    <Box
                      sx={{
                        width: '500px',
                        height: '500px',
                        backgroundImage: `url(${placeholderProperty})`,

                        backgroundSize: 'cover',
                      }}
                    />
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className='img__effect '>
        <Box
          sx={{
            width: '100%',
            height: '14rem',
            backgroundImage: `url(${
              img || placeholderProperty
            }),url(${placeholderProperty})`,
            backgroundSize: 'cover',
          }}
          onClick={handleImagesOpenDetails}
          style={{ cursor: 'pointer' }}
        />
        {/* <Link href='https://login.arackaldevelopers.com/sign-up'>
          <img
            src={img || placeholderProperty}
            onError={(e) => (e.target.src = placeholderProperty)}
          />
        </Link> */}
      </div>
      <div className='property__grid__single__inner'>
        <Box sx={{ display: 'flex' }}>
          <h4 onClick={handleOpenDetails} style={{ cursor: 'pointer' }}>
            {title.slice(0, 20)}
            {title.length > 20 && '...'}
          </h4>
        </Box>
        {/* <Typography
          sx={{
            display: 'inline',
            fontWeight: 'bolder',
            fontSize: '1.5rem',
            paddingTopics: '2rem',
            marginTop: 2,
          }}
        >
          {title}
        </Typography> */}
        {(singleItem.state || singleItem.country) && (
          <p className='sub__info d-flex align-items-center gap-1'>
            <FaMapMarkerAlt /> {location}
          </p>
        )}
        {/* <div className='progress__type'>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              aria-valuenow='25'
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
          <p className='project__has'>
            <span className='project__has__investors'>
              {totalInvest} Investors
            </span>{' '}
            |{' '}
            <span className='project__has__investors__amount'>
              $ {investAmount}
            </span>{' '}
            <span className='project__has__investors__percent'>
              ({investPer}%)
            </span>
          </p>
        </div> */}
        <div className='item__info'>
          <div className='item__info__single'>
            {share_price ? (
              <>
                <p>Share Price</p>
                <h6>{share_price + ' ₹'}</h6>
              </>
            ) : (
              <>
                <p>Price</p>
                <h6>{price + ' ₹'}</h6>
              </>
            )}
          </div>

          <div className='item__info__single'>
            {rate_of_dividend ? (
              <>
                <p>Rate of Divident</p>
                <h6>{rate_of_dividend || '-'}</h6>
              </>
            ) : (
              <>
                <p>Area Size</p>
                <h6>{area_size || '-'}</h6>
              </>
            )}
          </div>
        </div>
        <div className='invest__cta__wrapper'>
          {/* <div className='countdown__wrapper'>
            <p className='secondary d-flex align-items-center gap-1'>
              <FaClock /> Left to invest
            </p>
            -
          </div> */}
          <div className='invest__cta'>
            <Link
              href={`${redirect}/sign-up`}
              className='button button--effect'
            >
              Invest Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
