import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import axios from 'axios';
import Stape from '../../components/common/Stape';
import Featured from '../../components/homeThree/Featured';
import Hero from '../../components/homeThree/Hero';
import Invest from '../../components/homeThree/Invest';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';

// import "../styles/globals.scss"
/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */

const Index = ({ data, location, pageContext }) => {
  const { apiBaseUrl } = pageContext;

  const setting = data.ghostSettings;

  const [properties, setProperties] = React.useState();
  const [products, setProducts] = React.useState();

  React.useEffect(() => {
    axios
      .get(`${apiBaseUrl}/v1/marketing-site/property-management/properties`)
      .then((response) => {
        setProperties(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  React.useEffect(() => {
    axios
      .get(`${apiBaseUrl}/v1/marketing-site/product-management/products`)
      .then((response) => {
        setProducts(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <MetaData location={location} />
      <Layout isHome={true}>
        <Hero setting={setting} />
        <Invest />
        {/* <FeaturedProperties products={products || []} /> */}
        <Featured properties={properties || []} />
        <Stape />
        {/* <TestimonialSlider cls='testimonial--two' /> */}
        {/* <Market /> */}
        {/* <Pagination pageContext={pageContext} /> */}
      </Layout>
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allRestApiV1MarketingSitePropertyManagementProperties:
      PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query ghostSrttingsQuery {
    ghostSettings {
      cover_image
      description
      logo
      og_description
      og_title
      title
      version
    }
  }
`;
